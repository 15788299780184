// export default function normalizeStyles(element, styles, delay = 0) {
export default function normalize(element, styles) {
    const normalizedStyles = styles;
    let hasHeight = false;

    const keys = Object.keys(normalizedStyles);
    for (let i = 0, il = keys.length; i < il; ++i) {
        const key = keys[i];
        if (key === 'height') {
            if (!Array.isArray(normalizedStyles[key]) && normalizedStyles[key] === 'auto') {
                normalizedStyles[key] = window.getComputedStyle(element).height;
            } else {
                for (let j = 0, jl = normalizedStyles[key].length; j < jl; ++j) {
                    if (normalizedStyles[key][j] === 'auto') {
                        normalizedStyles[key][j] = window.getComputedStyle(element).height;
                    }
                }
            }
            hasHeight = true;
        }
    }

    return { normalizedStyles, hasHeight };
}
